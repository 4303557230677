import Vue from 'vue';
import Vuex from 'vuex';


import {getWeb3, getWeb3Accounts} from '@/util/getWeb3';


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    web3: {
      web3Instance: null,
      networkId: null,
      mskContractInstance: null,
      bapeContractInstance: null,
      cmlContractInstance: null,

      accounts: null,
    },
  },
  mutations: {
    registerWeb3Instance (state, payload) {
      const newWeb3 = {
        web3Instance: payload.web3Instance,
        networkId: payload.networkId,
        mskContractInstance: payload.mskContractInstance,
        bapeContractInstance: payload.bapeContractInstance,
        cmlContractInstance: payload.cmlContractInstance,
      }

      state.web3 = Object.assign({}, state.web3, newWeb3);
    },

    setAccounts(state, accounts) {
      state.web3 = Object.assign({}, state.web3, {accounts});
    },
  },
  actions: {
    registerWeb3 ({commit}, isConnectWithMetamaskProvider) {
      return getWeb3(isConnectWithMetamaskProvider)
        .then(result => {
          commit('registerWeb3Instance', result)
        })
        .catch(e => {
          console.log('error in action registerWeb3', e)
        })
    },

    async registerWeb3Accounts ({commit, state}) {
      let accounts;
      try {
        accounts = await getWeb3Accounts();
      }
      catch (e) {
        console.log('error in action setAccounts', e);
      }

      commit('setAccounts', accounts);
    },

    // registerWeb3WithRawData ({commit}, result) {
    //   commit('registerWeb3WithRawData', result);
    // },
  },
  modules: {
  },
});
