<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link>
      <router-link to="/mywallet">My Wallet</router-link>
      <p>
        <span>Marketplace</span>
        <span>(coming soon)</span>
      </p>
    </div> -->
    <router-view/>

  </div>
</template>

<script>

  export default {
    components: {
      // 'el-menu': Menu,
      // 'el-menu-item': MenuItem,
    },
    beforeCreate() {
    },
    mounted() {
      this.$store.dispatch('registerWeb3')

      /*
      let web3Provider;
      if (typeof web3 !== 'undefined') {
        web3Provider = web3.currentProvider;
        web3 = new Web3(web3.currentProvider);
      } else {
        // If no injected web3 instance is detected, fallback to Truffle Develop.
        web3Provider = new Web3.providers.HttpProvider('http://127.0.0.1:9545');
        web3 = new Web3(web3Provider);
      }
      */
    },
    methods: {
    },
    computed: {
    },
  };
</script>

<style lang="scss">

#nav {
  margin: 10px 30px;
  // float: right;
  text-align: right;

  .router-link-exact-active {
    border-bottom: 1px solid;
    opacity: 0.8;
 }

  p {
    display: inline-block;

    span {
      // font-size: 15px;
      display: block;

      &:last-child {
        position: absolute;
        font-size: 12px;
        line-height: 7px;
        opacity: 0.9;
      }
    }
  }

  > * {
    margin: 0 10px;
  }

  a {
    &:hover {
      // color: #000;

      border-bottom: 1px solid;
      opacity: 0.8;
    }
  }
} // #nav

a {
  color: #039be5;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 0.7;
  }
}

body {
  margin: 0;
  // color: rgba(0,0,0,0.87);
  font-weight: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;

  color: #272727;
  font-family: 'DM Sans', sans-serif;
  line-height: 1.8em;
  font-size: 18px;

  @media (max-width: 650px) {
    font-size: 15px;
  }

}

p {
  line-height: 1.8em;
}

h1,
h2,
h3 {
  margin: 0;
}

h1,
h2 {
  font-size: 78px;
  font-weight: bold;
  line-height: 0.9;
  letter-spacing: normal;
  color: #000;

  @media (max-width: 980px) {
    font-size: 50px;
  }
}

h3 {
  font-size: 27px;
  line-height: 1em;
}

ul {
  padding: 0 10px;
  list-style-position: inside;
  list-style-image: none;
  list-style-type: none;

  li:not(:last-child) {
    margin-bottom: 15px;
  }

  li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
