<template>
  <div>

    <div class="ape-background">
      <!-- <img src="/public-images/bape-bg.png"> -->
      <img src="/public-images/ROOM201.jpg">
    </div>

    <div class="intro">
      <div class="intro__content">
        <img src="/public-images/logo-1.svg">
        <p>The first union of today's leading communities</p>
        <!-- <p>The First Union Of The Best Communities!</p> -->
      </div>

      <p>Choose a token to claim</p>
      <img src="/public-images/arrow.svg">
    </div>

    <!--
      <h3>You have {{nftBalanceOf.msk}} Hashmasks</h3>
      <p>You can claim 10k $MSK</p>
    -->

    <div class="tokens-cards-wrapper">

      <!-- <div class="token-card" :class="{ 'disabled': !web3.accounts }" -->
      <div class="token-card"
        v-for="token in allTokens" :key="token.symbol" v-loading="isLoading[token.symbol]"
      >
        <div>
          <img :src="`/public-images/${token.symbol}-bg.jpg`">
        </div>
        <img :src="`/public-images/${token.symbol}-logo.jpg`">
        <h2>{{token.name}}</h2>
        <p class="token-card__symbol">${{token.symbol.toUpperCase()}}</p>

        <!-- START v-if for claiming -->
        <p v-if="true">
          Airdrop is finished
        </p>
        <p v-else-if="canClaim[token.symbol] === false">

          <span v-if="Number(nftBalanceOf[token.symbol]) > 0">
            You already claimed ${{token.symbol.toUpperCase()}}
          </span>
          <span v-else>You cannot claim ${{token.symbol.toUpperCase()}}</span>

        </p>
        <p v-else-if="canClaim[token.symbol] === true && claimingStatus[token.symbol]">
          {{ getClaimingText(token.symbol) }}
        </p>
        <el-tooltip v-else :disabled="web3.accounts !== null" content="First connect your wallet">
          <div>
            <el-button :class="`token-card__button--${token.symbol}`" @click="claim(token.symbol)"
              :disabled="!web3.accounts"
            >
              Claim
            </el-button>
          </div>
        </el-tooltip>
        <!-- END v-if for claiming -->

        <div class="token-card__timer" v-if="false">
          <!-- <p>Minting in</p> -->
          <div>
            <div>
              <h3>{{getTimer.days}}</h3> <p>days</p>
            </div>
            <div>
              <h3>{{getTimer.hours}}</h3> <p>hours</p>
            </div>
            <div>
              <h3>{{getTimer.minutes}}</h3> <p>min</p>
            </div>
          </div>
        </div>

      </div> <!-- token-card -->

      <div class="token-card token-card--cml">
        <div>
          <img :src="`/public-images/cml-bg.jpg`">
        </div>
        <img :src="`/public-images/cml-logo.jpg`">
        <h2>Arabian Camels</h2>
        <p class="token-card__symbol">$CML</p>
        <h3 style="margin-bottom: 20px;">Coming soon</h3>
      </div> <!-- token-card--cml -->

    </div>

    <!--  -->
    <!--  -->
    <!--  -->

    <!-- <div v-if="false">
      <div class="nft-card" >
        <div v-if="isLoading[token.symbol]">
          Loading...
        </div>

        <div v-else-if="canClaim[token.symbol] === null">
          <img :src="`/public-images/${token.symbol}-logo.jpg`" class="first-logo">
          <h2>Hashmasks</h2>
          <p>${{token.symbol.toUpperCase()}}</p>
        </div>

        <div v-else>
          <img :src="`/public-images/${token.symbol}-logo.jpg`">

          <h3 v-if="canClaim[token.symbol] === false">You cannot claim ${{token.symbol.toUpperCase()}}</h3>

          <div v-else-if="canClaim[token.symbol] === true && claimingStatus[token.symbol] === null">
            <h3>You can claim 10k ${{token.symbol.toUpperCase()}}</h3>
            <el-button @click="claim(token.symbol)">Claim</el-button>
          </div>

          <h4 v-else-if="canClaim[token.symbol] === true && claimingStatus[token.symbol]">
            {{ getClaimingText(token.symbol) }}
          </h4>
        </div>
      </div>
    </div> -->

    <!--  -->
    <!--  -->
    <!--  -->
    <!--  -->
    <!--  -->
    <!--  -->
    <!--  -->
    <!--  -->
    <!--  -->
    <!--  -->
    <!--  -->





    <el-button v-if="hasMetamask" class="new-metamask-button" :class="{ 'address-connected': web3.accounts }"
      @click="connectMetamask" 
    >
      <img src="@/assets/metamask.png">
      <p v-if="!web3.accounts">{{ isConnectingMetamask ? 'Connecting...' : 'Connect Your Wallet' }}</p>
      <p v-else>Connected address: {{ getFormattedConnectedAddress }}</p>
    </el-button>
    <h3 v-else style="text-align: center; margin: 80px 0;">Please install metamask</h3>

    <div style="text-align: center; margin-bottom: 60px; padding: 0 10px;">
      <a href="https://galleries.boredhash.com">
        Our next project is Bored Hash Galleries — the first dynamic NFT fully powered by Hashmasks and BAYC
      </a>
    </div>

    <hr>

    <div class="text-section">
      <div>
        <h2>What is <br> Bored Hash?</h2>
        <p>
          Bored Hash is a project to help the best NFT projects develop their own metaverses and DAOs.
        </p>
      </div>

      <img src="/public-images/undraw_festivities.svg">
    </div>

    <div class="text-section" style="padding-top: 20px; padding-bottom: 70px;">
      <div>
        <h2 style="font-size: 40px; text-align: center; margin-bottom: 30px;">Giveaway</h2>
        <Tweet id="1403725339742904326" :options="{ cards: 'hidden' }"></Tweet>
      </div>
      <!-- <img src="/public-images/undraw_festivities.svg"> -->
    </div>

    <div class="faq-section-wrapper">
      <div class="faq-section">
        <div>  
          <h3>How It Works?</h3>
          <p>
            We created 3 separate ERC20 tokens for Hashmasks, BAYC, and Arabian Camels owners.
            Now we're airdropping these tokens to owners. Each owner can claim 10,000 ERC20 tokens.
          </p>
        </div>

        <div>  
          <h3>Why?</h3>
          <p>
            Many people building projects around these NFTs but true metaverses are impossible without ERC20.
            Our tokens solve this problem. Now you can create incredible experiences in your metaverses.
            Moreover, communities can easily create their own DAOs with this airdrop — they only need to develop a governance mechanism!
          </p>
        </div>

        <div>  
          <h3>Does The Team Reserve Tokens?</h3>
          <p>
            <b>No</b>. We'll claim tokens like everyone else — only receiving an allocation for our own Hashmasks, Bored Apes, and Arabian Camels. 
          </p>
        </div>

      </div>
    </div>

    <div class="text-section">
      <div>
        <h2>Bored Hash Galleries</h2>
        <p>
          This airdrop is only a part of our project.
          Right now we are working on an NFT-collab of Hashmasks and BAYC communities.
          It will be the first dynamic NFT fully powered by Hashmasks and BAYC.
          For more details follow our Twitter and join our Discord.
        </p>
      </div>

      <div class="social-links">
        <a href="https://discord.gg/HExg2bx6uX">
          <img src="@/assets/discord-logo.svg">
          Discord
        </a>

        <a href="https://twitter.com/boredhash">
          <img src="@/assets/twitter-logo.png">
          Twitter
        </a>
      </div>
    </div>


    <div style="margin-bottom: 70px; text-align: center;">
      <h3>Verified Smart Contracts</h3>
      <ul>
        <li>
          $BAPE Token:
          <a :href="`https://etherscan.io/address/${getEnv('VUE_APP_BAPE_ADDRESS')}`">
            {{ getEnv('VUE_APP_BAPE_ADDRESS') }}
          </a>
        </li>

        <li>
          $MSK Token:
          <a :href="`https://etherscan.io/address/${getEnv('VUE_APP_MSK_ADDRESS')}`">
            {{ getEnv('VUE_APP_MSK_ADDRESS') }}
          </a>
        </li>

        <li>
          $CML Token:
          Coming Soon
          <!-- <a :href="`https://etherscan.io/address/${getEnv('VUE_APP_CML_ADDRESS')}`">
            {{ getEnv('VUE_APP_CML_ADDRESS') }}
          </a> -->
        </li>

      </ul>
    </div>

    <hr>
    <div class="footer" style="text-align: center; padding: 50px 0;">
      <img src="/public-images/logo-2.svg">
    </div>

 
  </div>

</template>

<script>

// import Web3Library from 'web3';
import Vue from 'vue';
import { Card, Popover, Button, Loading, Tooltip} from 'element-ui';
import { Tweet } from 'vue-tweet-embed'

Vue.use(Loading.directive);

export default {
  name: 'Homepage',
  components: {
    'el-card': Card,
    'el-popover': Popover,
    'el-button': Button,
    'el-tooltip': Tooltip,
    Tweet,
  },

  data() {
    return {
      isLoadingMainBlockhainData: true,
      isConnectingMetamask: false,

      allTokens: [
        {symbol: 'bape', name: 'BAYC'},
        {symbol: 'msk', name: 'Hashmasks'},
        // {symbol: 'cml', name: 'Arabian Camels'},
      ],

      nftBalanceOf: {
        msk: null,
        bape: null,
        cml: null,
      },

      canClaim: {
        bape: null,
        msk: null,
        cml: null,
      },

      isLoading: {
        bape: null,
        msk: null,
        cml: null,
      },

      claimingStatus: {
        bape: null,
        msk: null,
        cml: null,
      },
    };
  }, // data

  mounted() {
    if (this.isLoadingMainBlockhainData) {
      if (this.web3.networkId) {
        this.getBlockchainData();
      }
    }
  },

  watch: {
    // '$store.state.contractInstance': {
    'web3.networkId': {
      handler(newWeb3, oldWeb3) {
        if (this.isLoadingMainBlockhainData) {
          this.getBlockchainData();
        }
      },
      deep: true
    },
  },

  methods: {
    getEnv(envKey) {
      return process.env[envKey];
    },

    async getBlockchainData() {
      this.isLoadingMainBlockhainData = false;
    },

    async connectMetamask() {
      if (this.isLoadingMainBlockhainData) return;
      if (this.isConnectingMetamask) return;

      if (this.web3.networkId != 1) {
        alert(`Please switch your wallet to the Ethereum Mainnet and reload the page. Your current network id: ${this.web3.networkId}`)
        return;
      }

      this.isConnectingMetamask = true;

      await this.$store.dispatch('registerWeb3Accounts');
      this.isConnectingMetamask = false;

      // for (const symbol of Object.keys(this.canClaim)) {
      //   await this.loadCanClaim(symbol);
      // }

      Promise.all([
        this.loadCanClaim('bape'),
        this.loadCanClaim('msk'),
        // this.loadCanClaim('cml'),
      ]);
    },

    async loadCanClaim(tokenSymbol) {
      // alert(tokenSymbol)
      if (!this.web3.accounts) return;
      if (this.canClaim[tokenSymbol] !== null) return;
      if (this.isLoading[tokenSymbol]) return;
      this.isLoading[tokenSymbol] = true;

      this.nftBalanceOf[tokenSymbol] = await this.getTokenContract(tokenSymbol).methods.nftBalanceOf(this.web3.accounts[0]).call();
      console.log(`nftBalanceOf(${tokenSymbol}) = ${this.nftBalanceOf[tokenSymbol]}`);

      this.canClaim[tokenSymbol] = await this.getTokenContract(tokenSymbol).methods.canClaim(this.web3.accounts[0]).call();

      this.isLoading[tokenSymbol] = false;
    },

    async claim(tokenSymbol) {

      if (this.claimingStatus[tokenSymbol]) return;

      this.getTokenContract(tokenSymbol).methods.claim()
        .send({ from: this.web3.accounts[0] })
        .on("sending", (receipt) => {
          this.claimingStatus[tokenSymbol] = 'sending';
        })
        .on("transactionHash", (receipt) => {
          // alert('transactionHash')
          console.log('transactionHash', receipt)
          this.claimingStatus[tokenSymbol] = 'transactionHash';
        })
        // .on("receipt", (receipt) => {
        //   alert('receipt')
        //   console.log('receipt', receipt)
        //   this.unlockingNctStatus = 'receipt';
        // })
        .on("error", (error) => {
          let formattedError = error.message.slice(
            error.message.indexOf(`"reason":"`),
            error.message.indexOf(`"},"stack"`)
          );
          formattedError = formattedError.replace(`"reason":"`, '');

          alert(formattedError ? `Error: ${formattedError}` : error.message)
          this.claimingStatus[tokenSymbol] = null;
        });

    }, // claim

    getTokenContract(tokenSymbol) {
      if (tokenSymbol === 'msk') return this.web3.mskContractInstance;
      if (tokenSymbol === 'bape') return this.web3.bapeContractInstance;
      if (tokenSymbol === 'cml') return this.web3.cmlContractInstance;
    },

    getClaimingText(tokenSymbol) {
      if (this.claimingStatus[tokenSymbol] === 'sending') {
        return 'Waiting for your confirmation...';
      }

      // if (this.claimingStatus[tokenSymbol]  === 'transactionHash') return 'Sending transaction...'
      if (this.claimingStatus[tokenSymbol]  === 'transactionHash') return 'Transaction sent!'
      // if (this.claimingStatus[tokenSymbol]  === 'receipt') return 'Transaction sent!'
    },

  }, // methods

  computed: {
    web3 () {
      return this.$store.state.web3;
    },

    getFormattedConnectedAddress() {
      return this.web3.accounts[0].slice(0, 6) + '...' + this.web3.accounts[0].slice(-4);
    },

    hasMetamask() {
      return Boolean(window.ethereum);
    },

    getTimer() {
      const a = new Date();
      // const b = new Date('2021-07-12');

      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes());
      const utc2 = Date.UTC(2021, 6, 12, 16, 0);
      const diff = Math.floor(utc2 - utc1);

      const minute = 1000 * 60;
      const hour = minute * 60;
      const day = hour * 24;

      const days = Math.floor(diff/day);
      const hours = Math.floor(diff/hour) - (days * 24);
      const minutes = Math.floor(diff/minute) - (hours * 60) - (days * 24 * 60);

      return {
        days,
        hours,
        minutes,
      }

      // const day = 1000 * 60 * 60 * 24;
      // const days = Math.floor(diff/day);
      // const months = Math.floor(days/31);
      // const years = Math.floor(months/12);

      // const difference = Math.floor((utc2 - utc1) / _MS_PER_DAY);
      // alert(`1=${days}, 2=${hours}, 3=${minutes}`);
    },
  }, // computed
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  // tweet
  iframe {
    width: 500px !important;
    max-width: 100% !important;
  }

  hr {
    background: #979797;
    opacity: 0.19;
    margin: 0;
  }

  .ape-background {
    height: 900px;
    position: absolute;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.55);
      z-index: 2;
    }
  } // ape-background

  .intro {
    color: #fff;
    z-index: 5;
    padding-top: 120px;
    text-align: center;
    position: relative;

    & > p {
      margin-top: 250px;
      font-size: 22px;
      // font-weight: 500;
    }
  } // intro

  .intro__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    img {
      width: 216px;
      height: 186px;
      @media (min-width: 800px) {
        margin-right: 50px;
      }
    }

    p {
      max-width: 480px;
      margin: 0;
      font-size: 61px;
      line-height: 0.98;
      text-align: left;
      padding: 20px 0 20px 50px;

      border-left: 2px solid #fff;
      @media (max-width: 800px) {
        border-left: 0;
        text-align: center;
        padding-left: 0;
        font-size: 40px;
      }
    }
  }

  .tokens-cards-wrapper {
    display: flex;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
    position: relative;
    z-index: 3;
    // margin-top: 55px;
    flex-wrap: wrap;
  }

  .token-card {
    width: 250px;
    // height: 300px;
    // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 63px 0 rgba(0, 0, 0, 0.15);
    background: #fff;
    padding: 9px;
    border-radius: 8px;
    text-align: center;
    margin: 20px 30px;

    .el-button {
      // background: #000;
      color: #fff;
      border-radius: 20px;
      margin-bottom: 10px;

      // font-size: 18px;
      font-size: 15px;
      font-weight: bold;
      padding: 10px 22px;
      // border-radius: 17px;
    }

    & > div:nth-child(1) {
      position: relative;
      height: 200px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 8px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      }
    }

    & > img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 7px solid #fff;
      margin: auto;
      margin-top: -35px;
      display: block;
      position: relative;
    }

    h2 {
      font-size: 18px;
      line-height: 20px;
      margin-top: 13px;
    }

    p {
      color: rgba(0, 0, 0, 0.7);
      font-size: 15px;

      &.token-card__symbol {
        margin-top: 8px;
        margin-bottom: 15px;
        opacity: 0.37;
        font-size: 19px;
        font-weight: bold;
        color: #424242;

        // font-size: 16px;
      }
    }
  } // token-card

  .token-card__timer {
    text-align: center;
    margin-top: 35px;

    & > div {
      display: flex;
      justify-content: space-between;
      max-width: 120px;
      margin: auto;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    h3, p {
      margin: 0;
      color: #2F2F2F;
      font-weight: 500;
    }

    h3 {
      font-size: 22px;
    }

    p {
      font-size: 13.5px;
      line-height: 18px;
    }

    & > p {
      font-size: 18px;
      color: #a0a0a0;
    }
  }


  button.el-button {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
    // cursor: pointer;
    transition: all 0.2s;

    border: 0;
    text-decoration: none;
    text-shadow: none;
    // font-weight: 700;
    // letter-spacing: 1px;
    // color: #fff;
    // border: 1px solid #000;

    &:hover,
    &:active, 
    &:focus {
      color: inherit;

      &:not(.is-disabled) {
        opacity: 0.6;
        // box-shadow: none;
      }
    }

    &.is-disabled {
      border: 0;
      opacity: 0.4;
    }

    &.token-card__button--msk {
      &, &:hover, &:active,  &:focus {
        background: #ff1391;
        color: #fff;
      }
    }
    
    &.token-card__button--bape {
      &, &:hover, &:active,  &:focus {
        background: #000;
        color: #fff;
      }
    }

    &.token-card__button--cml {
      &, &:hover, &:active,  &:focus {
        background: #ff5000;
        color: #fff;
      }
    }
  } // button.el-button

  .new-metamask-button.el-button {
    max-width: 100%;
    background-color: #fff;
    margin: auto;
    display: block;
    margin-top: 100px;
    margin-bottom: 100px;
    font-weight: bold;
    border-radius: 30px;
    padding: 8px 30px;
    font-size: 18px;

    box-shadow: 0 11px 36px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px rgba(151, 151, 151, 0.2);


    &.address-connected {
      @media (max-width: 700px) {
        font-size: 12px;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    & > span {
      display: flex;
      align-items: center;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    p {
      margin: 0;
    }
  }

  .text-section {
    max-width: 1000px;
    padding: 150px 0;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    @media (max-width: 980px) {
      padding: 80px 0;
    }

    & > * {
      margin-left: 20px;
      margin-right: 20px;
    }

    & > div {
      max-width: 550px;
      // margin-top: 50px;
      // margin-bottom: 50px;

      p {
        margin-bottom: 0;
      }
    }

    & > img {
      width: 100%;
      max-width: 350px;
      // margin-left: 100px;

      @media (max-width: 980px) {
        margin-top: 50px;
      }
    }

    p {
      opacity: 0.57;
      font-size: 23px;
      line-height: 1.65;
      color: #424242;
    }
  }

  .faq-section-wrapper {
    // background: url('/public-images/mymask.jpg');
    background: url('/public-images/mymask2.jpg');
    // background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    padding: 100px 20px 150px;
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: 980px) {
      padding: 30px 20px 80px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.85);
      z-index: 1;
    }
  }

  .faq-section {
    display: flex;
    justify-content: center;
    color: #fff;
    max-width: 1350px;
    margin: auto;
    flex-wrap: wrap;

    & > div {
      z-index: 2;
      max-width: 380px;
      margin: 50px 20px 0;
    }

    p {
      margin: 0;
    }

    h3 {
      color: #fff;
      font-size: 39px;
      line-height: 50px;
      font-weight: bold;
      padding-bottom: 30px;
      margin-bottom: 30px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background: #fff;
        width: 80px;
        height: 1px;
      }
    }
  }

  .social-links {
    width: 300px;
    margin: auto;

    @media (max-width: 980px) {
      margin-top: 20px;
    }

    a {
      margin-top: 20px;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      color: #fff;
      font-size: 20px;
      transition: all 0.3s;

      &:hover {
        opacity: 0.7;
      }

      &:nth-child(1) {
        background: #5865F2; // discord
      }

      &:nth-child(2) {
        background: #1C9BF0; // twitter
      }
    }

    img {
      height: 20px;
      margin-right: 10px;
    }
  }

</style>
