import Web3 from 'web3';

// import CMLTokenAbi from '@/assets/HashmasksAbi.json';
import MSKTokenAbi from '@/assets/MSKToken.json';
import BAPETokenAbi from '@/assets/BAPEToken.json';
import CMLTokenAbi from '@/assets/CMLToken.json';

/*
* 1. Check for injected web3 (mist/metamask)
* 2. If metamask/mist create a new web3 instance and pass on result
* 3. Get networkId - Now we can check the user is connected to the right network to use our dApp
* 4. Get user account from metamask
* 5. Get user balance
*/

async function getWeb3() {

  // https://github.com/ChainSafe/web3.js/issues/1362

  // let provider = 'https://bsc-dataseed1.binance.org:443';
  let provider = window.ethereum;
  // if (window.ethereum) {
  //   provider = window.ethereum;
  // }
  // else {
  //   provider = process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8545' : null;
  //   // provider = 'https://data-seed-prebsc-1-s1.binance.org:8545';
  // }

  const web3 = new Web3(provider);

  const mskContractInstance = new web3.eth.Contract(MSKTokenAbi.abi, process.env.VUE_APP_MSK_ADDRESS)
  const bapeContractInstance = new web3.eth.Contract(BAPETokenAbi.abi, process.env.VUE_APP_BAPE_ADDRESS)
  const cmlContractInstance = new web3.eth.Contract(CMLTokenAbi.abi, process.env.VUE_APP_CML_ADDRESS)

  const networkId = await web3.eth.net.getId();

  return {
    // injectedWeb3: web3.isConnected(),
    networkId: networkId,
    mskContractInstance,
    bapeContractInstance,
    cmlContractInstance,

    web3Instance () {
      return web3
    }
  }

} // function

async function getWeb3Accounts() {
  let accounts;
  try {
    accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  }
  catch (error) {
    console.log('getWeb3 ERROR', error)
    // reject(new Error('Unable to connect to Metamask'))
    // if (error.code === 4001) {
    //   // User rejected request
    // }
    // setError(error);
  }

  return accounts;
}

export {getWeb3, getWeb3Accounts}
